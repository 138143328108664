import { isFunction } from 'lodash-es';

export function wrapPromise<T>(
	promise: (() => Promise<T>) | Promise<T>,
	before?: () => void,
	after?: () => void,
) {
	before?.();
	return Promise.resolve(isFunction(promise) ? promise() : promise).finally(after);
}
